import AppLogo from 'src/components/ui/AppLogo.vue'
import AppContainer from 'src/components/ui/AppContainer.vue'
import AppContent from 'src/components/ui/AppContent.vue'
import AppSection from 'src/components/ui/AppSection.vue'
import AppField from 'src/components/ui/AppField.vue'
import AppInput from 'src/components/ui/AppInput.vue'
import AppSelect from 'src/components/ui/AppSelect.vue'
import AppButton from 'src/components/ui/AppButton.vue'
import AppSeparator from 'src/components/ui/AppSeparator.vue'
import AppLink from 'src/components/ui/AppLink.vue'
import AppSocial from 'src/components/ui/AppSocial.vue'
import AppImg from 'src/components/ui/AppImg.vue'
import AppTooltip from 'src/components/ui/AppTooltip.vue'
import AppDialog from 'src/components/ui/AppDialog.vue'
import AppSkeleton from 'src/components/ui/AppSkeleton.vue'
import AppRating from 'src/components/ui/AppRating.vue'
import AppLabel from 'src/components/ui/AppLabel.vue'
import AppBadge from 'src/components/ui/AppBadge.vue'
import AppAvatar from 'src/components/ui/AppAvatar.vue'
import AppMediaItem from 'src/components/ui/AppMediaItem.vue'
import AppMenuButton from 'src/components/ui/AppMenuButton.vue'
import AppSpinner from 'src/components/ui/AppSpinner.vue'
import AppRadio from 'src/components/ui/AppRadio.vue'
import AppCheckbox from 'src/components/ui/AppCheckbox.vue'
import AppErrorMessage from 'src/components/ui/AppErrorMessage.vue'
import AppContentSpinner from 'src/components/ui/AppContentSpinner.vue'
import AppSectionSpinner from 'src/components/ui/AppSectionSpinner.vue'

export const components = {
  AppLogo,
  AppContainer,
  AppSection,
  AppInput,
  AppField,
  AppButton,
  AppSeparator,
  AppLink,
  AppSocial,
  AppImg,
  AppTooltip,
  AppDialog,
  AppSkeleton,
  AppRating,
  AppLabel,
  AppBadge,
  AppAvatar,
  AppMediaItem,
  AppMenuButton,
  AppSpinner,
  AppRadio,
  AppErrorMessage,
  AppSelect,
  AppCheckbox,
  AppContent,
  AppContentSpinner,
  AppSectionSpinner
}

declare module '@vue/runtime-core' {
  interface GlobalComponents {
    AppLogo: typeof AppLogo
    AppContainer: typeof AppContainer
    AppSection: typeof AppSection
    AppField: typeof AppField
    AppInput: typeof AppInput
    AppButton: typeof AppButton
    AppSeparator: typeof AppSeparator
    AppLink: typeof AppLink
    AppSocial: typeof AppSocial
    AppImg: typeof AppImg
    AppTooltip: typeof AppTooltip
    AppDialog: typeof AppDialog
    AppSkeleton: typeof AppSkeleton
    AppRating: typeof AppRating
    AppLabel: typeof AppLabel
    AppBadge: typeof AppBadge
    AppAvatar: typeof AppAvatar
    AppMediaItem: typeof AppMediaItem
    AppMenuButton: typeof AppMenuButton
    AppSpinner: typeof AppSpinner
    AppRadio: typeof AppRadio
    AppErrorMessage: typeof AppErrorMessage
    AppSelect: typeof AppSelect
    AppCheckbox: typeof AppCheckbox
    AppContent: typeof AppContent
    AppContentSpinner: typeof AppContentSpinner
    AppSectionSpinner: typeof AppSectionSpinner
  }
}
