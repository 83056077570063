<template>
  <div class="rating">
    <q-rating
      :icon="fasStar"
      :icon-selected="fasStar"
      :model-value="modelValue ?? 0"
      :readonly="readonly"
      no-dimming
      @update:model-value="(value: Props['modelValue']) => emit('update:model-value', value)"
    />
  </div>
</template>

<script setup lang="ts">
import { fasStar } from '@quasar/extras/fontawesome-v6'
import type { Nullable } from 'src/types'

export interface Props {
  modelValue: Nullable<number>
  readonly?: boolean
}

export interface Emits {
  (event: 'update:model-value', value: Props['modelValue']): void
}

withDefaults(defineProps<Props>(), {
  readonly: false
})

const emit = defineEmits<Emits>()
</script>

<style lang="scss" scoped>
.rating:deep(.q-rating) {
  display: flex;
  color: var(--theme-color-rating-stars-inactive);
  transition: color var(--trs-1);

  .q-rating__icon-container + .q-rating__icon-container {
    margin-left: 0.15em;
  }

  .q-rating__icon {
    text-shadow: none;
  }

  .q-rating__icon--active {
    color: var(--theme-color-rating-stars);
  }
}
</style>
