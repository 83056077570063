<template>
  <q-radio
    class="app-radio"
    :model-value="modelValue"
    :dense="true"
    :val="val"
    @update:model-value="value => emit('update:modelValue', value)"
  >
    <div class="app-radio__label">
      {{ label }}
    </div>

    <span v-if="$slots.default" class="app-radio__description text-muted">
      <slot />
    </span>
  </q-radio>
</template>

<script lang="ts" setup>
import type { QRadioProps } from 'quasar'

interface Props {
  modelValue: QRadioProps['modelValue']
  val: QRadioProps['val']
  label: QRadioProps['label']
}

interface Emits {
  (e: 'update:modelValue', value: Props['modelValue']): void
}

defineProps<Props>()
const emit = defineEmits<Emits>()
</script>

<style lang="scss" scoped>
.app-radio {
  align-items: start;
  font-size: 14px;
  line-height: 20px;
  &__label {
    line-height: 20px;
  }
  &:deep(.q-radio__label) {
    display: flex;
    flex-direction: column;
    padding-left: var(--sm);
  }
}
</style>
