<template>
  <q-checkbox
    class="app-checkbox"
    :model-value="modelValue"
    :dense="true"
    :disable="disable"
    @update:model-value="onUpdate"
  >
    <div class="app-checkbox__label">
      <slot v-if="$slots.default" />
      <template v-else>{{ label }}</template>

      <span v-if="$slots.suffix" class="app-checkbox__suffix q-ml-xs">
        <slot name="suffix" />
      </span>
    </div>

    <span v-if="$slots.description" class="app-checkbox__description text-muted">
      <slot name="description" />
    </span>
  </q-checkbox>
</template>

<script lang="ts" setup>
import type { QCheckboxProps } from 'quasar'

interface Props {
  modelValue?: boolean
  label?: QCheckboxProps['label']
  disable?: boolean
}

interface Emits {
  (e: 'update:modelValue', value: Props['modelValue']): void
}

withDefaults(defineProps<Props>(), {
  disable: false
})

const emit = defineEmits<Emits>()

const onUpdate = (value: boolean) => {
  emit('update:modelValue', value)
}
</script>

<style lang="scss" scoped>
.app-checkbox {
  align-items: start;
  font-size: 14px;
  line-height: 20px;
  transition: color var(--trs-1);
  &__label {
    line-height: 20px;
  }
  &:deep(.q-radio__label) {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
  &:deep(.q-checkbox__bg) {
    border-width: 1px;
  }
  &:deep(.q-checkbox__svg) {
    width: calc(100% - 2px) !important;
    height: calc(100% - 2px) !important;
    left: 1px;
    top: 1px;
  }
  &.disabled {
    opacity: 0.6 !important;
    color: var(--theme-color-muted) !important;
    &:deep(.q-checkbox__inner) {
      color: inherit !important;
    }
  }
}
</style>
