<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

export interface Props {
  light?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  light: false,
  readonly: false
})

const classes = computed(() => ([
  'app-field',
  {
    'app-field--light': props.light
  }
]))
</script>

<style lang="scss">
.app-field {
  width: 100%;
  --bg: #{$grey-3};
  --height: 40px;
  --mx: 10px;
  --px: 14px;
  --py: calc((var(--height) - (var(--font-size) * var(--line-height))) / 2);
  --color: var(--theme-color);
  --font-size: 14px;
  --line-height: 1.5;

  .q-field {
    &__native, &__prefix, &__suffix {
      padding-top: var(--py) !important;
      padding-bottom: var(--py) !important;
    }

    &__native, &__prefix, &__suffix, &__input {
      color: var(--color) !important;
      line-height: var(--line-height) !important;
      transition: color var(--trs-1) !important;
    }

    &__input {
      min-height: calc(var(--height) - var(--py) * 2);
    }

    &__native {
      &[type="password"] {
        letter-spacing: 0.25em;
      }
      &::placeholder {
        letter-spacing: normal;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-animation-name: initial !important;
        -webkit-animation-fill-mode: initial !important;
        -webkit-text-fill-color: var(--color) !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }
      & > span {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
    }

    .q-field__prepend, .q-field__before {
      padding-right: var(--mx) !important;
    }
    .q-field__after, .q-field__append {
      padding-left: var(--mx) !important;
    }

    .q-field__control, .q-field__marginal {
      height: var(--height);
    }

    &__marginal {
      font-size: 18px !important;
      color: var(--theme-color-muted) !important;
      transition: color var(--trs-1) !important;
      & > * + * {
        margin-left: var(--mx);
      }
    }

    &__control {
      transition: background-color var(--trs-1) !important;
      border-radius: var(--sm) !important;
      padding: 0 14px !important;
      background-color: var(--bg) !important;

      &::before {
        background-color: var(--color) !important;
        opacity: 0 !important;
        transition: opacity var(--trs-1), background-color var(--trs-1) !important;
      }

      &::after {
        display: none;
      }
    }

    &--readonly {
      .q-field__native {
        cursor: default !important;
      }
    }

    &:not(.q-field--readonly) {
      .q-field__control {
        &:hover {
          &::before {
            opacity: 0.05 !important;
          }
        }
      }
    }

    &--highlighted:not(.q-field--readonly) {
      .q-field__control {
        box-shadow: none !important;
        background-color: var(--bg) !important;
      }
    }

    &--rounded {
      .q-field__control {
        border-radius: calc(var(--height) / 2) !important;
      }
    }
  }

  .q-textarea {
    .q-field__control-container {
      padding: 0 !important;
    }

    .q-field__native, .q-field__prefix, .q-field__suffix {
      line-height: var(--line-height) !important;
    }

    .q-field__native {
      --min-rows: 4;
      min-height: var(--height) !important;
    }

    .q-field__control {
      height: auto !important;
      min-height: var(--height) !important;
    }
  }

  .q-select {
    .q-field__marginal {
      color: var(--color) !important;
    }
  }

  &--light {
    --bg: var(--theme-color-light);
  }
}

.body--dark .app-field {
  --bg: #{$grey-8};
}
</style>
