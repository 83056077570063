<template>
  <div class="menu-button">
    <div v-if="skeleton" class="menu-button-skeleton">
      <app-skeleton type="circle" class="menu-button-skeleton__icon" />
      <div class="menu-button-skeleton__text">
        <app-skeleton class="text-body1" type="text" :style="{ width: `${random(40, 80)}%` }" />
      </div>
    </div>

    <app-button
      v-else
      class="menu-button__element"
      :tag="tag"
      :label="label"
      :icon="icon"
      :icon-right="arrow ? iconChevronRight : undefined"
      :to="to"
      :color="active ? 'primary' : 'transparent'"
      :badge="badge"
      :badge-color="badgeColor"
      :no-hover="active"
      no-radius
      align="left"
      @click="emit('click')"
    >
      <template v-if="$slots.label" #label>
        <slot name="label" />
      </template>

      <template v-if="$slots.append" #append>
        <slot name="append" />
      </template>
    </app-button>
  </div>
</template>

<script setup lang="ts">
import iconChevronRight from 'assets/icons/light/chevron-right.svg?raw'
import type { Props as ButtonProps } from 'src/components/ui/AppButton.vue'
import type { Props as BadgeProps } from 'src/components/ui/AppBadge.vue'
import type { RouteLocationRaw } from 'vue-router'
import { random } from 'lodash'

interface Props {
  tag?: ButtonProps['tag']
  skeleton?: boolean
  label?: string | number
  icon?: string
  to?: RouteLocationRaw
  active?: boolean
  arrow?: boolean
  badge?: number | string
  badgeColor?: BadgeProps['color']
}

withDefaults(defineProps<Props>(), {
  skeleton: false,
  active: false,
  arrow: false
})

const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>

<style lang="scss" scoped>
.menu-button {
  --parent-height: var(--menu-button-height, 40px);
  --parent-px: var(--menu-button-px, var(--md));
  --parent-ps: var(--menu-button-ps, var(--parent-px));
  --parent-pe: var(--menu-button-pe, var(--parent-px));
  --parent-gap: var(--menu-button-gap, 14px);
  --parent-icon-size: var(--menu-button-icon-size, 18px);
}

.menu-button__element {
  --icon-weight: 300 !important;
  --icon-size: var(--parent-icon-size) !important;
  --ps: var(--parent-ps) !important;
  --pe: var(--parent-pe) !important;
  --gap: var(--parent-gap) !important;
  --size: var(--parent-height) !important;
  --font-weight: 400 !important;
  display: flex;
  width: 100%;
  &:deep(.button__icon-right) {
    margin: 0 0 0 auto;
  }
}

.menu-button-skeleton {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 var(--parent-px);
  height: var(--parent-height);
  &__icon {
    width: var(--parent-icon-size);
    height: var(--parent-icon-size);
    margin-right: var(--parent-gap);
    flex: 0 0 auto;
  }
  &__text {
    flex: 1 1 auto;
  }
}
</style>
