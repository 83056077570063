<template>
  <app-field
    :readonly="readonly"
    :light="light"
    :class="['app-input', { 'app-input--textarea': textarea }]"
  >
    <q-input
      ref="inputRef"
      :model-value="modelValue"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :autogrow="autogrow"
      :loading="loading"
      :clearable="clearable"
      :autofocus="autofocus"
      :unmasked-value="unmaskedValue"
      :prefix="prefix"
      :reverse-fill-mask="reverseFillMask"
      :readonly="readonly"
      :debounce="debounce"
      :rounded="rounded"
      :type="type"
      :name="name"
      :disable="disable"
      :mask="mask"
      :class="{ 'q-field--light': light }"
      :inputmode="inputmode"
      :autocomplete="autocomplete"
      @update:model-value="onUpdate"
      @blur="onBlur"
      @focus="onFocus"
    >
      <slot />

      <template v-if="$slots.prepend" #prepend>
        <slot name="prepend" />
      </template>

      <template v-if="$slots.append || error" #append>
        <slot name="append" />

        <ui-icon
          v-if="error"
          class="text-negative"
          :icon="iconCircleExclamation"
        />
      </template>
    </q-input>

    <div v-if="counter" class="app-input__counter text-caption text-muted">
      {{ `${modelValueLength}${maxlength ? '/' + maxlength : ''}` }}
    </div>
  </app-field>
</template>

<script setup lang="ts">
import iconCircleExclamation from 'assets/icons/light/circle-exclamation.svg?raw'
import UiIcon from 'src/components/ui/UiIcon.vue'
import type { Props as AppFieldProps } from 'src/components/ui/AppField.vue'
import type { QInputProps } from 'quasar'
import { QInput } from 'quasar'
import { computed, ref } from 'vue'
import { INPUT_DEBOUNCE } from 'src/config'
import { useEventListener } from '@vueuse/core'
import type { InputMode } from 'src/types/components'
import { isNil } from 'lodash'

export interface Props {
  modelValue?: QInputProps['modelValue']
  id?: string
  type?: QInputProps['type']
  fillMask?: QInputProps['fillMask']
  autogrow?: boolean
  rounded?: QInputProps['rounded']
  prefix?: QInputProps['prefix']
  placeholder?: string
  clearable?: QInputProps['clearable']
  transparent?: boolean
  debounce?: number | boolean
  readonly?: boolean
  disable?: QInputProps['disable']
  textAlign?: 'left' | 'center'
  label?: string
  name?: QInputProps['name']
  mask?: QInputProps['mask']
  error?: QInputProps['error']
  reverseFillMask?: QInputProps['reverseFillMask']
  unmaskedValue?: QInputProps['unmaskedValue'],
  counter?: boolean,
  autocomplete?: string,
  autofocus?: boolean,
  loading?: boolean,
  textarea?: boolean,
  inputmode?: InputMode
  light?: AppFieldProps['light']
  maxlength?: number
}

export interface Emits {
  (e: 'update:model-value', value: Props['modelValue']): void,
  (e: 'blur'): void,
  (e: 'focus'): void
  (e: 'focusNative'): void
}

const props = withDefaults(defineProps<Props>(), {
  rounded: false,
  type: 'text',
  readonly: false,
  textAlign: 'left',
  transparent: false,
  counter: false,
  inputmode: 'text',
  textarea: false,
  autogrow: false,
  light: false,
  autofocus: false
})

const emit = defineEmits<Emits>()

let blurCount = 0

const inputRef = ref<QInput | null>(null)

const nativeEl = computed(() => inputRef.value?.nativeEl)

const isTextarea = computed(() => props.textarea || props.autogrow)

const maxlength = computed(() => {
  if (!isNil(props.maxlength)) return props.maxlength
  return isTextarea.value ? 500 : 32
})

const autogrow = computed(() => isTextarea.value)

const modelValueLength = computed(() => {
  if (!props.modelValue) return 0
  return String(props.modelValue).length
})

const debounce = computed(() => {
  if (!props.debounce) return
  if (props.debounce === true) return INPUT_DEBOUNCE
  return props.debounce
})

useEventListener<FocusEvent>(nativeEl, 'focus', () => {
  emit('focusNative')
})

const onUpdate = (value: Props['modelValue']) => {
  if (props.type === 'number') {
    value = value === '' || isNil(value) ? undefined : Number(value)
    emit('update:model-value', value)
  } else {
    emit('update:model-value', value)
  }
}

const blur = () => {
  inputRef.value?.blur()
}

const onBlur = () => {
  blurCount++
  if (props.autocomplete && blurCount < 2) return

  emit('blur')
}

const onFocus = () => {
  emit('focus')
}

defineExpose({ blur })
</script>

<style lang="scss" scoped>
.app-input {
  &__counter {
    padding: var(--xs) 0 0 0;
  }

  &--textarea:deep(.q-textarea .q-field__native) {
    min-height: calc((var(--font-size) * var(--line-height)) * var(--min-rows) + var(--py) * 2) !important;
  }
}
</style>
