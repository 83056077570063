<template>
  <router-link
    :to="{ name: 'main', params: { locale: $route.params.locale } }"
    :title="version"
    class="app-logo"
  >
    <logo class="app-logo__element" />
  </router-link>
</template>

<script setup lang="ts">
import Logo from 'assets/img/logo.svg?component'

const version = process.env.VERSION
</script>

<style lang="scss" scoped>
.app-logo {
  display: block;
  &__element {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
