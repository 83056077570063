<template>
  <component
    :is="tag"
    :to="to"
    :class="classes"
  >
    <q-img
      :key="src"
      class="app-img"
      :loading="loading"
      :fetchpriority="fetchpriority"
      :src="src"
      :alt="alt"
      :ratio="ratio"
      :srcset="srcset"
      :fit="fit"
      :no-spinner="!spinner"
      @error="isError = true"
      @load="isError = false"
    />
    <transition name="fade">
      <div v-if="!fallbackBg && (!props.src || isError)" class="app-img-placeholder">
        <no-picture-placeholder class="app-img-placeholder__icon" />
      </div>
    </transition>
  </component>
</template>

<script setup lang="ts">
import NoPicturePlaceholder from 'assets/img/no-picture-placeholder.svg?component'
import { ref } from 'vue'
import type { NamedColor, QImgProps } from 'quasar'
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

interface Props {
  src?: QImgProps['src']
  alt?: QImgProps['alt']
  srcset?: QImgProps['srcset']
  ratio?: QImgProps['ratio']
  radius?: boolean
  fit?: QImgProps['fit']
  spinner?: QImgProps['noSpinner']
  tag?: string
  to?: RouteLocationRaw
  bw?: boolean
  loading?: QImgProps['loading']
  fetchpriority?: QImgProps['fetchpriority']
  fallbackBg?: NamedColor
}

const props = withDefaults(defineProps<Props>(), {
  fit: 'cover',
  spinner: false,
  tag: 'div',
  bw: false,
  radius: false
})

const isError = ref(false)

const tag = computed(() => {
  return props.to ? 'router-link' : props.tag
})

const classes = computed(() => {
  const arr = [
    'app-img',
    {
      'app-img--bw': props.bw,
      'app-img--radius': props.radius,
    }
  ]

  if (props.fallbackBg && isError.value) {
    arr.push(`bg-${props.fallbackBg}`)
  }

  return arr
})
</script>

<style lang="scss" scoped>
.app-img {
  @include rounded-corners-render-fix;
  position: relative;
  display: block;
  color: var(--theme-color);
  transition: color var(--trs-1), background-color var(--trs-1);
  &--radius {
    overflow: hidden;
    border-radius: var(--global-border-radius-inner);
  }
}

.app-img:deep(.q-img) {
  height: 100%;
  .q-img__image--with-transition {
    transition: opacity var(--trs-1);
  }
}

.app-img--bw {
  filter: grayscale(100%);
  opacity: 0.5;
}

.app-img-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &__icon {
    --app-icon-size: 100% !important;
    opacity: 0.25;
  }
}
</style>
