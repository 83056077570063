<template>
  <div :class="['avatar', `avatar--${type}` ]">
    <span v-if="name" class="avatar__letter">{{ avatarLetter }}</span>
    <img
      v-else-if="image"
      :src="image"
      alt="avatar"
      class="avatar__image"
    >
    <app-skeleton v-else class="avatar__skeleton" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

export type Type = 'skeleton' | 'name' | 'image'

export interface Props {
  name?: string
  image?: string
}

const props = defineProps<Props>()

const avatarLetter = computed(() => {
  return props.name?.replace(/\s/g, '')[0]
})

const type = computed<Type>(() => {
  if (props.name) return 'name'
  if (props.image) return 'image'
  return 'skeleton'
})
</script>

<style lang="scss" scoped>
.avatar {
  --size: 40px;
  --font-size: 16px;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  &__skeleton {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &__letter {
    font-weight: 700;
    font-size: var(--font-size);
    line-height: 1;
    text-transform: uppercase;
  }
  &__image {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.avatar--name {
  background-color: var(--theme-color-primary-muted);
  color: var(--theme-color-primary);
  transition: background-color var(--trs-1), color var(--trs-1);
}
</style>
