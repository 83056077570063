<template>
  <component
    :is="tag"
    :to="to"
    :class="classes"
    :style="{ '--color': color ?? undefined }"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

export type Preset = 'positive' | 'negative' | 'inactive'

export interface Props {
  to?: RouteLocationRaw
  color?: string
  size?: 'sm'
  preset?: Preset
}

const props = withDefaults(defineProps<Props>(), {
  size: 'sm'
})

const tag = computed(() => {
  return props.to ? 'router-link' : 'div'
})

const classes = computed(() => {
  return [
    'label',
    `label--size-${props.size}`,
    props.preset ? `label--preset-${props.preset}` : ''
  ]
})
</script>

<style lang="scss" scoped>
.label {
  --color: var(--theme-color-primary);
  display: inline-flex;
  align-items: center;
  user-select: none;
  transition: color var(--trs-1);
  color: var(--color-light);
  background-color: var(--color);
  padding: 0 var(--px);
  border-radius: var(--xs);
  text-decoration: none;
  line-height: 1;
  height: var(--size);
  &--size-sm {
    --px: 10px;
    --size: var(--lg);
  }

  /* preset */
  &--preset-positive {
    --color: var(--theme-color-positive);
  }
  &--preset-negative {
    --color: var(--theme-color-negative);
  }
  &--preset-inactive {
    --color: var(--theme-color-muted);
  }
}
</style>
