<template>
  <component
    :is="tag"
    :to="to"
    :class="[
      'app-link',
      `app-link--color-${color}`,
      {
        'app-link--disabled': disabled,
        'app-link--dashed': dashed
      }
    ]"
  >
    <ui-icon
      v-if="icon"
      :icon="icon"
      class="app-link__icon"
    />

    <slot />

    {{ label }}

    <ui-icon
      v-if="iconRight"
      :icon="iconRight"
      class="app-link__icon"
    />
  </component>
</template>

<script setup lang="ts">
import UiIcon from 'src/components/ui/UiIcon.vue'
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

interface Props {
  to?: RouteLocationRaw,
  tag?: string
  icon?: string
  iconRight?: string
  disabled?: boolean
  dashed?: boolean,
  label?: string | number
  color?: 'link' | 'theme' | 'accent' | 'muted'
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'a',
  disabled: false,
  color: 'theme',
  dashed: false,
})

const tag = computed(() => {
  return props.to ? 'router-link' : props.tag
})
</script>

<style lang="scss" scoped>
.app-link {
  --icon-size: 1em;

  display: inline-flex;
  align-items: center;
  gap: 0 0.5em;
  text-decoration: none;
  cursor: pointer;
  color: var(--app-link-color);
  transition: color var(--trs-1), border-color var(--trs-1), opacity var(--trs-1);

  &__icon {
    font-size: var(--icon-size);
  }

  body.desktop &:not(.app-link--disabled):hover {
    color: var(--app-link-color-hover, var(--app-link-color));
  }
}

.app-link--color-theme {
  --app-link-color: var(--theme-color);
  --app-link-color-hover: var(--theme-color-primary);
}

.app-link--color-link {
  --app-link-color: var(--theme-color-link);
  --app-link-color-hover: var(--theme-color-primary);
}

.app-link--color-muted {
  --app-link-color: var(--theme-color-muted);
  --app-link-color-hover: var(--theme-color);
}

.app-link--color-accent {
  --app-link-color: var(--theme-color-primary);
  --app-link-color-hover: var(--theme-color);
}

.app-link--disabled {
  color: var(--theme-color-muted);
  cursor: default;
}

.app-link--dashed:not(.app-link--disabled) {
  border-bottom: 1px currentColor dashed;
}
</style>
