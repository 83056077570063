<template>
  <component :is="tag" :class="classes">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

export type Preset = 'xl' | 'lg' | 'md'
export type Background = 'accent' | 'primary' | 'primary-muted' | 'secondary' | 'light' | 'muted'

export interface Props {
  tag?: string
  transparent?: boolean
  disabled?: boolean
  loading?: boolean
  rounded?: boolean
  bordered?: boolean
  preset?: Preset
  bg?: Background
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  transparent: false,
  disabled: false,
  loading: false,
  rounded: false,
  bordered: false
})

const classes = computed(() => {
  const { bg, transparent, rounded, disabled, loading, preset, bordered } = props

  return [
    'content',
    {
      'content--transparent': transparent,
      'content--disabled': disabled,
      'content--loading': loading,
      'content--rounded': rounded,
      'content--bordered': bordered
    },
    preset ? `content--preset-${preset}` : '',
    (bg && !transparent) ? `content--bg-${bg}` : ''
  ]
})
</script>

<style lang="scss" scoped>
.content {
  background-color: var(--theme-color-bg);
  transition: opacity var(--trs-1), background-color var(--trs-1), border-color var(--trs-1);

  &--transparent {
    background-color: transparent;
  }

  &--rounded {
    border-radius: var(--global-border-radius);
  }

  &--bordered {
    border: 1px solid var(--theme-color-separator);
  }

  &--bg {
    &-accent {
      background-color: var(--theme-color-accent);
    }
    &-primary {
      background-color: var(--theme-color-primary);
    }
    &-primary-muted {
      background-color: var(--theme-color-primary-muted);
    }
    &-secondary {
      background-color: var(--theme-color-secondary);
      color: var(--color-light);
    }
    &-muted {
      background-color: var(--theme-color-bg-muted);
    }
  }

  &--disabled, &--loading {
    opacity: 0.5;
    &:deep(> *) {
      pointer-events: none;
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--loading {
    cursor: progress;
  }

  &--preset-md {
    --py: var(--md);
    --px: var(--md);
    padding: var(--py) var(--px);
  }

  &--preset-lg {
    --py: var(--lg);
    --px: var(--lg);
    padding: var(--py) var(--px);
    @media (max-width: $breakpoint-sm-max) {
      --py: var(--lg);
      --px: var(--md);
    }
  }

  &--preset-xl {
    --py: var(--xl);
    --px: var(--xl);
    padding: var(--py) var(--px);
    @media (max-width: $breakpoint-lg-max) {
      --py: var(--lg);
      --px: var(--lg);
    }
    @media (max-width: $breakpoint-sm-max) {
      --px: var(--md);
    }
  }
}
</style>
